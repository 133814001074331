import React, { useEffect } from 'react';
import { navigate } from "@reach/router"

export default function Muchado() {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.location = '/much-ado-over-texting';
          }
      }, []);
      return null;
}
